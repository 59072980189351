import { getBaseDataApi } from '@/api/common';
import { headInfo } from '@/config/seo';
import { useShareProject } from '@/stores/shareProject.js';
import { useUserInfo } from '@/stores/user';
import 'driver.js/dist/driver.css';
import { debounce, isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import type { FormIds } from '~/components/share/project/type';
import { GuideIds } from '~/components/share/project/type';
import { COMMUNITY_BASE_DATA } from '~/types/constant';
// import { ModeType } from '~/types/share';
export const useSeo = () => {
  const setSeo = () => {
    useHead({
      titleTemplate: `Template - Atomm`,
      link: [
        {
          rel: 'shortcut icon',
          type: 'image/x-icon',
          href: headInfo.favicon,
        },
      ],
      meta: [
        {
          property: 'og:image',
          content: headInfo.ogImage,
        },
      ],
    });
  };

  onMounted(() => {
    setSeo();
  });
};
// 新手指引
export const useGuide = async () => {
  const shareProjectStore = useShareProject();

  const { sensorEditorClick } = useSensors();

  const hasInstruction = computed(() => shareProjectStore.form.instructionForm.hasInstruction);
  const forSale = computed(() => shareProjectStore.form.basicForm.forSale);
  const id = computed(() => shareProjectStore.form.id);
  const currentStepId = computed(() => shareProjectStore.currentStepId);
  const commonClass = '!bg-[#2068FB] layout-driver-custom';
  const driverConfig = {
    stagePadding: 12,
    allowClose: false,
    overlayOpacity: 0,
    disableActiveInteraction: true,
    progressText: '<div class="text-white/[0.7]">{{current}} / {{total}}</div>',
    showButtons: [],
    nextBtnText: 'Next',
  };
  let driverObj: any;
  // let timer = null as any;

  // 第一次进入编辑器
  const initDriverStep1 = async () => {
    const obj = { step1_v3_4: true } as any;

    localStorage.setItem('reportDriver', JSON.stringify(obj));
    const { driver } = (await import('driver.js')) as any;
    driverObj = driver({
      steps: [
        {
          element: '#' + GuideIds.mediaLibrary,
          popover: {
            description:
              '<div ><span class="text-[24px]">1</span>/2</div><div div class= "mt-4  text-level-6 w-full font-sans" > You can upload and manage media files here. Also, AImake generated drawings can be found here. Try dragging files here to upload new media.</div>',
            showButtons: ['next', 'close'],
            disableButtons: [],
            onNextClick: () => {
              driverObj.drive(1);
              // restTimer();
              handleSensorClick.click('newcreator_1_Done/Next');
            },
            onCloseClick: () => {
              closeGuide();
              handleSensorClick.click('newcreator_1');
            },
            side: 'bottom',
            align: 'center',
            popoverClass: commonClass + ' media-library-guide-wrap',
          },
        },
        {
          element: '#' + GuideIds.projectFiles,
          popover: {
            description: `<div id='project-files-guide'><span class="text-[24px]">2</span>/2</div><div div class= "mt-4  text-level-6 w-full font-sans" >
            Atomm can automatically extract processing settings from XCS files.
            </div>`,
            nextBtnText: 'Done',
            showButtons: ['next', 'close'],
            disableButtons: [],
            onNextClick: () => {
              driverObj.destroy();
              handleSensorClick.click('newcreator_2_Done/Next');
            },
            onCloseClick: () => {
              closeGuide();
              handleSensorClick.click('newcreator_2');
            },
            side: 'bottom',
            align: 'center',
            popoverClass: commonClass + ' project-files-guide-wrap',
          },
        },
      ],
      ...driverConfig,
    });
    driveStart();
    // 元素离开视口隐藏指引
    const observer = new IntersectionObserver(
      (entry: IntersectionObserverEntry[]) => {
        const guideEle = document.querySelector('.project-files-guide-wrap') as HTMLElement;
        if (guideEle && !entry[0]?.isIntersecting) {
          guideEle.style.display = 'none';
        } else if (guideEle && entry[0]?.isIntersecting) {
          guideEle.style.display = 'block';
        }
      },
      { threshold: 0.1 },
    );
    const targetElement = document.querySelector('#' + GuideIds.projectFiles) as HTMLElement;
    observer.observe(targetElement);
    const observer2 = new IntersectionObserver(
      (entry: IntersectionObserverEntry[]) => {
        const guideEle = document.querySelector('.media-library-guide-wrap') as HTMLElement;
        if (guideEle && !entry[0]?.isIntersecting) {
          guideEle.style.display = 'none';
        } else if (guideEle && entry[0]?.isIntersecting) {
          guideEle.style.display = 'block';
        }
      },
      { threshold: 0.1 },
    );
    const targetElement2 = document.querySelector('#' + GuideIds.mediaLibrary) as HTMLElement;
    observer2.observe(targetElement2);
  };
  const initDriverStep3 = async () => {
    const obj = getStorage();
    obj.step2_v3_4 = true;
    localStorage.setItem('reportDriver', JSON.stringify(obj));
    const { driver } = (await import('driver.js')) as any;
    driverObj = driver({
      steps: [
        {
          element: '#' + GuideIds.instruction,
          popover: {
            description:
              ' <div div class= "mt-4 text-level-6 w-full font-sans" >You can use a template or upload instruction files here, use a template need to fill in the description. </div>',
            showButtons: ['next', 'close'],
            disableButtons: [],
            nextBtnText: 'Done',
            onNextClick: () => {
              driverObj.destroy();
              // clearInterval(timer);
              handleSensorClick.click('newcreator_3_Done/Next');
            },
            onCloseClick: () => {
              closeGuide();
              handleSensorClick.click('newcreator_3');
            },
            side: 'bottom',
            align: 'center',
            popoverClass: commonClass + ' instruction-guide3-wrap',
          },
        },
      ],
      ...driverConfig,
    });
    driveStart();
    // 元素离开视口隐藏指引;
    const observer = new IntersectionObserver(
      (entry: IntersectionObserverEntry[]) => {
        const guideEle = document.querySelector('.instruction-guide3-wrap') as HTMLElement;
        if (guideEle && !entry[0]?.isIntersecting) {
          guideEle.style.display = 'none';
        } else if (guideEle && entry[0]?.isIntersecting) {
          guideEle.style.display = 'block';
        }
      },
      { threshold: 0.1 },
    );
    const targetElement = document.querySelector('#' + GuideIds.instruction) as HTMLElement;
    observer.observe(targetElement);
  };

  const driveStart = () => {
    const screen = window.screen.width;
    if (screen < 768) {
      return;
    }
    driverObj.drive();
    // clearInterval(timer);
    // timer = setInterval(() => {
    //   if (driverObj) {
    //     driverObj.destroy();
    //   }
    // }, 10000);
  };
  // const restTimer = () => {
  //   clearInterval(timer);
  //   timer = setInterval(() => {
  //     driverObj.destroy();
  //   }, 10000);
  // };
  const closeGuide = () => {
    if (driverObj) {
      driverObj.destroy();
      // clearInterval(timer);
      driverObj = null;
      const obj = {
        step1_v3_4: true,
        step2_v3_4: true,
      };
      localStorage.setItem('reportDriver', JSON.stringify(obj));
    }
  };

  const getStorage = () => {
    const reportDriver = localStorage.getItem('reportDriver') as any;
    const obj = JSON.parse(reportDriver);
    return obj ? obj : {};
  };

  // 判断显示新手指引- 步骤三里的新手指引
  watch(
    () => hasInstruction.value,
    (val) => {
      setTimeout(() => {
        const reportDriver = getStorage();

        if (val === true && !reportDriver.step2_v3_4 && currentStepId.value === 'instructionForm') {
          initDriverStep3();
        }
      }, 200);
    },
  );

  const handleSensorClick = {
    click: (name: string) => {
      sensorEditorClick({
        element_name: name,
        sevice_type: 'project',
        content_type: forSale.value ? 'sale' : 'free',
        content_id: Number(id.value),
      });
    },
  };

  const getStatus = async () => {
    const reportDriver = getStorage();
    if (!reportDriver.step1_v3_4) {
      initDriverStep1();
    }
  };

  onMounted(() => {
    getStatus();
  });
  onBeforeUnmount(() => {
    if (driverObj) {
      driverObj.destroy();
      // clearInterval(timer);
      driverObj = null;
    }
  });
};

// 导航step滚动，滚动监听
export const useScroll = () => {
  const useShareProjectStore = useShareProject();
  const currentStepList = computed(() => useShareProjectStore.currentStepList);
  const handleScroll = () => {
    const layout = document.querySelector('#project-layout') as HTMLElement;
    if (!layout) {
      return;
    }
    const scrollTop = layout.offsetTop - 32 - 32;
    const scrollY = window.scrollY;
    const length = currentStepList.value.length;
    const tops = [] as number[];
    // 得到每个菜单的top值
    for (let index = 0; index < length; index++) {
      const id = String(currentStepList.value[index]?.id);
      const ele = document.getElementById(id) as HTMLElement;
      tops.push(ele.offsetTop + scrollTop + ele.offsetHeight);
    }
    // 判断当前滚动到哪个菜单
    for (let i = 0 as number; i < tops.length; i++) {
      if (scrollY < Number(tops[i])) {
        const id = String(currentStepList.value[i]?.id) as FormIds;
        useShareProjectStore.changeStep(id);
        break;
      }
    }
  };

  const debouncedHandleScroll = debounce(() => {
    handleScroll();
  }, 100);

  onMounted(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', debouncedHandleScroll);
  });
};

// 权限检验&离开弹窗
export const useAuth = (isChangeRouter: any) => {
  const userInfoStore = useUserInfo();
  const route = useRoute();
  const router = useRouter();
  const saleErrorModal = ref(false);
  const checkWhiteStatus = async () => {
    // 非白名单用户，没有权限进入bundle页
    await userInfoStore.updateWhitesStatus();
    if (route.path === '/share/bundle' && !userInfoStore.isSaleMaker) {
      saleErrorModal.value = true;
    }
  };
  const { sensorPrimeLimitClick } = useSensors();
  const handleSaleErrorModal = () => {
    sensorPrimeLimitClick();
    router.push('/');
  };
  const showLeaveConfirm = (next: any) => {
    if (saleErrorModal.value) {
      next();
    } else if (!isChangeRouter.value) {
      Modal.confirm({
        wrapClassName: 'editor-del-modal',
        content: 'Are you sure you want to leave?',
        cancelText: 'No',
        okText: 'Yes',
        keyboard: false,
        transitionName: '',
        onOk: () => {
          next(true);
        },
        onCancel: () => {
          next(false);
        },
      });
    } else {
      next();
    }
  };

  onBeforeRouteLeave((to, from, next) => {
    showLeaveConfirm(next);
  });

  onBeforeRouteUpdate((to, from, next) => {
    showLeaveConfirm(next);
  });

  onMounted(() => {
    nextTick(() => {
      checkWhiteStatus();
    });
  });
  return { saleErrorModal, handleSaleErrorModal };
};

export const initBaseData = () => {
  const useShareProjectInfo = useShareProject();

  const { baseData } = storeToRefs(useShareProjectInfo);

  /** 初始化所有映射数据 */

  const getBaseProcessTypesData = async () => {
    const { data: baseProcessTypesData, error: baseProcessTypesError } = await getBaseDataApi(COMMUNITY_BASE_DATA.PROCESS);
    if (!baseProcessTypesError.value) {
      baseData.value.baseProcessTypes = baseProcessTypesData.value?.data;
    }
  };

  const getBaseBitmapsData = async () => {
    const { data: baseBitmapsData, error: baseBitmapsDataError } = await getBaseDataApi(COMMUNITY_BASE_DATA.BITMAP);
    if (!baseBitmapsDataError.value) {
      baseData.value.baseBitmaps = baseBitmapsData.value?.data;
    }
  };
  const getBaseModeData = async () => {
    const { data: baseProcessTypesData, error: baseProcessTypesError } = await getBaseDataApi(COMMUNITY_BASE_DATA.MODE);
    if (!baseProcessTypesError.value) {
      baseData.value.baseModes = baseProcessTypesData.value?.data;
    }
  };
  const getBasePowerData = async () => {
    const { data: baseMaterialData, error: baseMaterialError } = await getBaseDataApi(COMMUNITY_BASE_DATA.POWER);
    if (!baseMaterialError.value) {
      baseData.value.basePower = baseMaterialData.value?.data;
    }
  };
  const getBaseLaserData = async () => {
    const { data: baseMaterialData, error: baseMaterialError } = await getBaseDataApi(COMMUNITY_BASE_DATA.LASER);
    if (!baseMaterialError.value) {
      baseData.value.baseLaser = baseMaterialData.value?.data;
    }
  };

  onMounted(() => {
    nextTick(() => {
      getBaseProcessTypesData();
      getBaseBitmapsData();
      getBaseModeData();
      getBasePowerData();
      getBaseLaserData();
    });
  });
};

// 自动保存需求
export const useAutoSave = (contentStatus: any, save: any) => {
  const useShareProjectStore = useShareProject();
  const form = computed(() => useShareProjectStore.form);
  const isEdit = computed(() => useShareProjectStore.isEdit);
  const isAutoSave = ref(false); // 是否已开启自动保存
  let flag = null as any;
  let interval = null as any;
  const time = ref(0);
  // 定时自动保存
  const autoSave = () => {
    setTimeout(() => {
      if (time.value !== 0) {
        time.value = time.value + 1;
      }
    }, 60 * 1000);
    interval = setTimeout(
      async () => {
        save(true);
        time.value = 1;

        autoSave();
      },
      2 * 60 * 1000,
    );
  };
  function removeTimeout() {
    clearInterval(interval);
    interval = null;
  }
  // 表单初始化完成后，判断是否有修改过，有修改过则开启自动保存
  const initWatch = () => {
    flag = watch(
      () => [form.value.basicForm, form.value.productionForm, form.value.wrapUpForm, form.value.instructionForm],
      (newVal) => {
        // 离开路由时会清空store，多加层empty判断
        const isEmptyVal = isEmpty(newVal[0]) && isEmpty(newVal[1]) && isEmpty(newVal[2]) && isEmpty(newVal[3]);
        if (!isAutoSave.value && !isEmptyVal) {
          isAutoSave.value = true;
          save(true); // 先直接保存再触发定时器自动保存
          time.value = 1;
          autoSave();
        }
      },
      { deep: true },
    );
  };

  watch(
    () => [contentStatus.isRequested, isEdit.value],
    () => {
      // 初始化接口完成
      if (contentStatus.isRequested && !isAutoSave.value) {
        // 已发布的内容, 不进行自动保存, 避免覆盖线上版本
        if (contentStatus.isPublished) {
          removeTimeout();
          return;
        }
        // 编辑状态： 草稿箱的内容，2分钟保存一次
        else if (isEdit.value) {
          isAutoSave.value = true;
          time.value = 1;
          autoSave();
          return;
          // 新案例：编辑后，立即保存，再开启2分钟保存一次
        } else {
          setTimeout(() => {
            initWatch(); // 开始监听编辑器内容
          }, 500);
        }
      }
    },
  );
  onUnmounted(() => {
    removeTimeout();
    flag && flag(); // 移除监听
  });
  return { removeTimeout, time };
};
